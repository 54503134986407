img {
  width: "100%";
  object-fit: contain;
  transition: all 0.25s linear;
  border-radius: 8px;
}

.img:hover {
  transform: rotate(1deg) scale(1.05, 1.05);
  filter: brightness(102%);
}

.page-title h2 {
  font-size: 55px;
  color: #2196f3;
  font-weight: 900;
  text-transform: uppercase;
}

.page-title .title-head-subtitle {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
  margin: 20px 0 0;
  opacity: .8;
  font-family: 'Open Sans', sans-serif;
}