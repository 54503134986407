.body {
	 min-height: 100vh;
	 background-color: black;
   }
.heading-section{
	color:#2196F3;
}
.form-control-login {
	background: transparent;
	height: 50px;
	/* color: white !important; */
	background: rgba(255, 255, 255, 0.08);
	border-radius: 100px !important;
	padding-left: 20px;
	padding-right: 20px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	opacity: 0.65;
	margin-top: 10px;
	}
