.resume-items{
    color:#2196F3;
}
.resume-items .item .bullet:before,.resume-items .item-label {
    line-height: 40px!important;
    top: 0;
    text-align: center;
    color: #fff;
  }
  
  .resume-items .item .card,.resume-items .item .card-header {
    background-color: transparent;
    font-size: 16px;
    border: 0;
  }
  
  .resume-items .item .card-header {
    border-bottom: 0;
    position: relative;
    padding: 32px 0 10px;
  }
  
  .resume-items .item .card-header > span {
    font-family: 'Poppins', sans-serif;
    font-size:16px;
  }
  
  .resume-items .item .card-header .year {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    top: -2px;
    font-size: 13px;
    opacity: .8;
    font-weight: 400;
  }
  
  .resume-items .item .card-header .year i {
      padding-right: 10px;
  }
  .resume-items .item .card-header .year i.fa-caret-right {
      display:none;
  }
  
  .resume-items .item .card-header > span > span {
    font-weight: 600;

  }
  
  .resume-items .item .card-header .separator {
    display: inline-block;
    width: 10px;
    background: #555;
    height: 2px;
    margin: 0 7px 3px 7px;
    vertical-align: middle;
  }
  
  .resume-items .item .card-body {
    padding: 0;
  }
  
  .resume-items .item .card-body p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin: 0;
    line-height: 28px;
    color:white;
  }
  
  .resume-items .item {
    position: relative;
  }
  
  .resume-items .item:not(:last-child) {
    padding-bottom: 50px;
  }
  
  .resume-items .item:before {
    content: '';
    display: none;
    height: 100%;
    left: 7px;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 1px;
    border-left: 1px solid #2196F3;
  }
  
  .resume-items .item-label {
    display: block;
    font-weight: 600;
    height: 40px;
    left: 50%;
    margin-left: -60px;
    margin-top: -20px;
    position: absolute;
    width: 120px;
    z-index: 10;
    border-radius: 4px;
  }
  
  .resume-items .item .bullet {
    display: none;
    height: 15px;
    left: 0px;
    position: absolute;
    background: #fff;
    top: 0;
    width: 15px;
    border-radius: 50%;
  }
  
  .resume-items .item .card {
    width: 100%;
  }
  
  @media (min-width:768px) {
    
    

    .resume-items .item .bullet,.resume-items .item:before {
      display: block;
    }
  
    .resume-items .item-label {
      left: 0;
      margin-left: 0;
      margin-top: 0;
    }
  
    .resume-items .item .card {
      margin-left: 39px;
      width: calc(100% - 39px);
    }
  }
  
.skills-title {
    padding-top: 50px;
  }
  
  .skill-text {
    display: block;
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .chart-bar {
    border-radius: 2px;
    display: block;
    height: 1px;
    position: relative;
    width: 100%;    
    background: #ddd;
    margin-bottom: 45px;
  }
  
  .chart-bar>.item-progress {
    border-radius: 2px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1%;
  }
  
  .chart-bar>.percent {
    height: 32px;
    margin-top: -41px;
    position: absolute;
    right: calc(100% - 40px);
    width: 46px;
    z-index: 10;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 31px;
    top: 50%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }
  
  .chart-bar>.percent span {
    position: relative;
    display: block;
  }
  
  
  
  .arrow {
      display: inline-block;
      padding: 4px;
      transform: translateY(33.5px) rotate(45deg) skew(3deg, 3deg) translateX(-27px);
      background-color: #FFF;
      bottom:8px;
      position:absolute;
  }
  
  .btn-resume {
    margin-top: 30px;
  }
  .btn{
    color:white !important;
  } 

  
.personal-info {
	align-items:center;

}

.personal-info .info-title-section {
	margin-bottom:10px;
}

.personal-info .image-container {
    position: relative;
    display: block;
	max-width:440px;
	margin:0 auto;
    z-index: 0;

    
}

.personal-info .image-container:before {
	display: inline-block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translate3d(26px, 26px, -26px);
}
@media (min-width:1025px) {
	.personal-info .row {
		margin-top:26px;
	}
.personal-info .image-container:before {
    border: 6px solid #2196F3 !important;
}
}

.personal-info p {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
    line-height: 32px;
	margin: 17px 0 16px;
}

body.light .personal-info p {
	margin: 15px 0 14px;
}

.personal-info ul {
  list-style: none;
  padding: 0;
  margin-top: -8px;
}

.personal-info ul li {
  position: relative;
}

.personal-info ul h6 {
  margin: 5px 0 0;
  font-family: 'Open Sans', sans-serif;
  text-transform: inherit;
  font-weight: 500;
}

body.light .personal-info ul h6 {
	font-weight: 600;
}

.personal-info ul li:not(:last-child) {
  padding-bottom: 21px;
}

.personal-info ul li span {
  text-transform: capitalize;
  margin-bottom: 7px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  opacity: .8;
  padding-right: 20px;
}

