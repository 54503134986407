img {
  width: "100%";
  object-fit: contain;
  transition: all 0.25s linear;
  border-radius: 10px 10px 0 0% !important;
}

.img:hover {
  transform: rotate(1deg) scale(1.05, 1.05);
  filter: brightness(102%);
  border-radius: 10px !important;

}
  
.page-title h2 {
  font-size: 55px;
  color: #2196f3;
  font-weight: 900;
  text-transform: uppercase;
}

.page-title .title-head-subtitle {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
  margin: 20px 0 0;
  opacity: .8;
  font-family: 'Open Sans', sans-serif;
}
.projectCardBody{
  background-color: black;
  border-radius: 0 0 10px 10px !important;
}
.three-d-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  background-color: #2196f3;
  color: #fff;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
  margin-left: 20px;
}

.three-d-button:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transform: scaleY(0);
  transform-origin: 0% 0%;
  transition: transform 0.3s;
}

.three-d-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.three-d-button:hover:before {
  transform: scaleY(1);
}


.category-buttons {
  display: flex;
  justify-content: space-around;
}

.category-button {
  background-color: #0074e4;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white; /* İkon rengi */
  transition: color 0.3s;
}

.category-button.active {
  color: black; /* Seçili kategori için farklı bir renk */
}

/* CSS for small screens */
@media (max-width: 768px) {
  .web-buttons {
    display: none;
  }
  .small-screen-buttons {
    display: block;
  }
}

/* CSS for web */
@media (min-width: 769px) {
  .web-buttons {
    display: block;
  }
  .small-screen-buttons {
    display: none;
  }
}

/* Add these styles to your Project.css or a separate CSS file */

/* Pagination container */
.paggination-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
  list-style: none;

}

/* Individual pagination button */
.paggination-buttons a {
  display: inline-block;
  padding: 8px 16px;
  margin: 0 4px;
  background-color: #2196f3; /* Change to your desired background color */
  color: #fff; /* Change to your desired text color */
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

/* Active page button */
.paggination-buttons a.active {
  background-color: #007bff; /* Change to your desired active button color */
}

/* Hover effect */
.paggination-buttons a:hover {
  background-color: #007bff; /* Change to your desired hover color */
}
