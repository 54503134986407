
.WebApp{
  display: flex;
  flex-direction: column;
  background-color: #222;
  min-height: 100vh;
  color:white;
}
.App-home {
  margin-top: 2%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: white;
}
.homeImg{
  min-height: 100vh;
  background-color:#010101;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width:768px) {
    .phoneText{
      font-size: 40px !important;
      margin-top: 60px;
      }
    
}

@media (max-width:400px) {
  .phoneText{
    margin-top: 85px;
    }
}

