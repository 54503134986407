img {
  width: "100%";
  object-fit: contain;
  transition: all 0.25s linear;
  border-radius: 10px 10px 0 0% !important;
}

.img:hover {
  transform: rotate(1deg) scale(1.05, 1.05);
  filter: brightness(102%);
  border-radius: 10px !important;

}
  
.page-title h2 {
  font-size: 55px;
  color: #2196f3;
  font-weight: 900;
  text-transform: uppercase;
}

.page-title .title-head-subtitle {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
  margin: 20px 0 0;
  opacity: .8;
  font-family: 'Open Sans', sans-serif;
}
.projectCardBody{
  background-color: black;
  border-radius: 0 0 10px 10px !important;
}

 .contact .social-links a {
  font-size: 25px;
  display: inline-block;
  background: white;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  transition: 0.3s;

}

 .contact .social-links a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}
