.phoneAnimationText{
  max-width: 500px;
  color: "#0d6efd";
  font-size: 50px !important;
  text-align: center;
}
@media (max-width:768px) {
  .phoneAnimationText{
    font-size: 40px !important;
    }
}

@media (max-width:400px) {
.phoneAnimationText{
  margin-top: 45px;
  font-size: 30px !important;
  }
}
